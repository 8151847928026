import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";

import { CustomIcon } from "@components/CustomIcon";
import MultiSelectTag, { MultiSelectTagPropsItem } from "@components/MultiSelectTag";
import appStore from "@store/AppStore";
import { ICatalogItemReference } from "@store/CatalogItem/CatalogItemReferenceStore";

import { TCellRendererProps } from "../types";

const ChildRefsCell = (props: TCellRendererProps) => {
  const { data } = props;
  const catalogItem = data?.catalogItem;

  if (!catalogItem) {
    return null;
  }

  const refMapper = (childRef: ICatalogItemReference) => ({
    label: childRef.name || childRef.referencedCatalogItem?.name || "",
    icon: childRef.referencedCatalogItem && <CustomIcon icon={childRef.referencedCatalogItem.icon} />,
    id: childRef.id,
  });

  const selectedItem = data?.catalogItem?.aliveChildReferences.map(refMapper) ?? [];

  const options = appStore.orgModel.catalogItems.catalogItems.map(i => ({
    label: i.name,
    id: i.id,
    icon: <CustomIcon icon={catalogItem.icon} />,
  }));

  const handleChildSelect = (item: MultiSelectTagPropsItem) => {
    appStore.orgModel.catalogItems.addCatalogItemReference({
      id: uuidv4(),
      referencedCatalogItemId: item.id,
      parentCatalogItemId: catalogItem.id,
    });
  };

  const handleChildRemove = (item: MultiSelectTagPropsItem) => {
    appStore.orgModel.catalogItems.removeCatalogItemReference(item.id);
  };

  return <MultiSelectTag onItemSelect={handleChildSelect} selectedItems={selectedItem} items={options} onItemRemove={handleChildRemove} />;
};

export default observer(ChildRefsCell);
