import { Component } from "react";
import { EditableText, FormGroup, IconName, Intent, Menu, MenuDivider, PopoverInteractionKind, Position } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { Popover } from "@components/Popover";
import appStore from "@store/AppStore";
import { StatusType } from "@store/StatusDefinitionStore";
import { IStatusDefinition } from "@store/StatusDefinitionStore";

import "./GlobalStatus.scss";

type GlobalStatusProps = {
  status: IStatusDefinition;
  isMenu: boolean;
};

// TODO this component exists for the sole purpose of using the TypeIcon method. We should consider extracting
// that function, or using STATUS_MENU_ITEMS, and get rid of this component.
class GlobalStatus extends Component<GlobalStatusProps> {
  private handleChangeDataType(type: StatusType) {
    this.props.status.setType(type);
  }

  private handleChangeLabel = (label: string) => {
    this.props.status.setLabel(label);
  };

  public static TypeIcon(statusType: StatusType): IconName {
    switch (statusType) {
      case StatusType.text:
        return "italic";
      case StatusType.number:
        return "numerical";
      case StatusType.check:
        return "tick";
      case StatusType.date:
        return "calendar";
      case StatusType.singleSelect:
        return "star";
      case StatusType.multiSelect:
        return "multi-select";
      case StatusType.mention:
        return "person";
      case StatusType.url:
        return "link";
    }
    return "blank";
  }

  render() {
    const propertyBlockMenu = (
      <div>
        <Menu>
          <MenuDivider title="Move" />
          <MenuItem icon="chevron-up" text="Up" disabled e2eIdentifiers="up" />
          <MenuItem icon="chevron-down" text="Down" disabled e2eIdentifiers="down" />
          <MenuDivider title="Actions" />
          <MenuItem
            intent={Intent.DANGER}
            icon="trash"
            text="Clear"
            onClick={() => appStore.workspaceModel?.deleteStatusDefinition(this.props.status)}
            e2eIdentifiers="clear"
          />
        </Menu>
      </div>
    );

    const statusMenuItems = [...Object.values(StatusType)].map(type => (
      <MenuItem
        key={type}
        icon={GlobalStatus.TypeIcon(type)}
        onClick={() => this.handleChangeDataType(type)}
        selected={this.props.status.type === type}
        text={type}
        e2eIdentifiers={["status-menu", type.toLowerCase()]}
      />
    ));

    const statusTypeMenu = (
      <div>
        <Menu>{statusMenuItems}</Menu>
      </div>
    );

    return (
      <div className="status-block">
        <FormGroup inline>
          {this.props.isMenu ? (
            <Popover
              inheritDarkTheme
              interactionKind={PopoverInteractionKind.HOVER}
              hoverCloseDelay={500}
              position={Position.LEFT_TOP}
              content={propertyBlockMenu}
            >
              <Button
                className="status-block-button"
                minimal
                intent={Intent.NONE}
                icon="drag-handle-vertical"
                e2eIdentifiers={["global-status", "drag"]}
              />
            </Popover>
          ) : null}
          <Popover
            inheritDarkTheme
            interactionKind={PopoverInteractionKind.HOVER}
            hoverCloseDelay={500}
            position={Position.LEFT_TOP}
            content={statusTypeMenu}
          >
            <Button
              minimal
              intent={Intent.NONE}
              icon={GlobalStatus.TypeIcon(this.props.status.type)}
              e2eIdentifiers={["global-status", "no-intent"]}
            />
          </Popover>
          <EditableText
            className="status-label"
            maxLength={36}
            multiline={false}
            key={`${this.props.status.id}-${this.props.status.label}`}
            placeholder="Name"
            defaultValue={this.props.status.label}
            selectAllOnFocus={false}
            confirmOnEnterKey
            onConfirm={this.handleChangeLabel}
          />
          <span />
        </FormGroup>
      </div>
    );
  }
}

export default observer(GlobalStatus);
