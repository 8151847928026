import { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";

import ColumnTogglePanel, { TStateColumn } from "@components/Modeling/ModelingFrame/Table/TableComponent/Components/ColumnTogglePanel";
import { parseGridColumnsToState } from "@components/Modeling/ModelingFrame/Table/TableComponent/utils";
import appStore from "@store/AppStore";
import { ITableColumn } from "@store/TableColumnStore";

interface IRequirementsColumnTogglePanelProps {
  columns: ITableColumn[];
  onClose(): void;
}

const RequirementsColumnTogglePanel = (props: IRequirementsColumnTogglePanelProps) => {
  const { columns, onClose } = props;
  const { requirementsTableGridApi } = appStore.env;
  const [columnsState, setColumnsState] = useState<TStateColumn[]>([]);
  const hasVisibilityChanges = useMemo(
    () =>
      columnsState.some(state => {
        const originalColumn = columns.find(c => c.id === state.colId);
        return originalColumn ? !!originalColumn.hide !== state.hide : false;
      }),
    [columns, columnsState]
  );

  useEffect(() => {
    if (requirementsTableGridApi) {
      parseGridColumnsToState(requirementsTableGridApi);
    }
  }, [requirementsTableGridApi]);

  if (!requirementsTableGridApi) {
    return null;
  }

  const handleSave = () => {
    columnsState.forEach(column => {
      const originalColumn = columns.find(c => c.id === column.colId);
      if (originalColumn && !!originalColumn.hide !== column.hide) {
        originalColumn.setHide(column.hide);
      }
    });
    onClose();
  };

  const handleCancel = () => {
    columnsState.forEach(column => {
      const originalColumn = columns.find(c => c.id === column.colId);
      if (originalColumn) {
        requirementsTableGridApi.setColumnsVisible([column.colId], !originalColumn.hide);
      }
    });
    parseGridColumnsToState(requirementsTableGridApi);
    onClose();
  };

  return (
    <ColumnTogglePanel
      api={requirementsTableGridApi}
      columnsState={columnsState}
      disableSave={!hasVisibilityChanges}
      onColumnsStateChange={setColumnsState}
      onCancel={handleCancel}
      onSave={handleSave}
    />
  );
};

export default observer(RequirementsColumnTogglePanel);
