import { useCallback, useEffect, useState } from "react";
import { GridApi } from "ag-grid-community";

/** For this to work well, it's recommended to provide the rowHeight to AgGrid **/
export const useScrollGridToLastRow = (gridApi?: GridApi) => {
  const [shouldScroll, setShouldScroll] = useState<boolean>();

  useEffect(() => {
    if (shouldScroll && gridApi) {
      const lastIndex = gridApi.getDisplayedRowCount() - 1;
      gridApi.ensureIndexVisible(lastIndex, "bottom");
      setShouldScroll(false);
    }
  }, [gridApi, shouldScroll]);

  const scrollToLastColumn = useCallback(() => setShouldScroll(true), []);

  return scrollToLastColumn;
};
