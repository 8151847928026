import isNull from "lodash/isNull";
import omitBy from "lodash/omitBy";

import { convertDateStringsToTimestamp, IObjectWithDates, IObjectWithDateStrings } from "@utilities/Date";

interface IObjectWithId {
  id: string;
}

export const mapRtoToSnapshot = <T extends IObjectWithId & IObjectWithDateStrings, S extends IObjectWithId & IObjectWithDates>(
  entry: T
): S => {
  return convertDateStringsToTimestamp(omitBy(entry, isNull)) as S;
};

/**
 * Remove fields with null values and convert date strings to timestamps
 */
export const toMapSnapshot = <T extends IObjectWithId & IObjectWithDateStrings, S extends IObjectWithId & IObjectWithDates>(
  entries?: T[],
  mapperFunc: (entry: T) => S = mapRtoToSnapshot
): Record<string, S> => {
  return (
    entries?.reduce((acc: Record<string, S>, entry) => {
      if (entry?.id) {
        acc[entry.id] = mapperFunc(entry);
      }
      return acc;
    }, {}) ?? {}
  );
};

export const addEntriesToParent = <E extends { id: string }, P extends object>(
  entries: E[],
  parentIdKey: keyof E,
  parentsMap: Record<string, P>,
  entryIdsKey: keyof P
) => {
  entries.forEach(rawEntry => {
    const entry = omitBy(rawEntry, isNull) as E;
    const parentId = entry[parentIdKey];

    if (typeof parentId !== "string") {
      console.error(`The key ${parentIdKey.toString()} seems to be invalid, since entry[parentKey] is not a string:`, parentId);
      return;
    }

    const parent = parentsMap[parentId];
    if (parent) {
      if (parent[entryIdsKey] === undefined || parent[entryIdsKey] === null) {
        parent[entryIdsKey] = [] as any;
      }
      const entryIds = parent[entryIdsKey];
      if (Array.isArray(entryIds) && !entryIds.includes(entry.id)) {
        entryIds.push(entry.id);
      }
    }
  });
};
