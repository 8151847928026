import { useState } from "react";
import { useParams } from "react-router-dom";
import { Divider } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import Text, { TextColor, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import { SearchInput } from "@components/SearchInput";
import SearchResults from "@components/SearchResults";
import { useAppNavigate } from "@router/hooks";
import { AnalysisType } from "@store/Analysis/AnalysisStore";
import appStore from "@store/AppStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";

import AnalysisSidebarItem from "../AnalysisSidebarItem/AnalysisSidebarItem";

import styles from "./AnalysisSidebar.module.scss";

export interface AnalysisSidebarProps {
  minimal?: boolean;
}
const AnalysisSidebar = ({ minimal }: AnalysisSidebarProps) => {
  const workspace = useWorkspace();
  const { codeBlockId } = useParams();
  const codeBlocks = workspace.analysis.analyses.filter(analysis => analysis.analysisType === AnalysisType.CodeBlock);
  const spreadsheets = workspace.analysis.analyses.filter(analysis => analysis.analysisType === AnalysisType.Spreadsheet);
  const [searchValue, setSearchValue] = useState("");
  const { navigateToCodeBlock } = useAppNavigate();
  const searchResult = codeBlocks.filter(codeBlock => codeBlock.label.toLowerCase().includes(searchValue.toLowerCase()));

  const renderSearch = () => {
    if (minimal) {
      return <BlueprintIcon className={styles.analysisSidebarSearchIcon} icon="search" outline />;
    }

    return <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} />;
  };

  return (
    <div className={styles.analysisSidebar}>
      {renderSearch()}
      <div>
        {searchValue ? (
          <SearchResults activeId={codeBlockId} onClick={navigateToCodeBlock} items={searchResult} />
        ) : (
          codeBlocks.toReversed().map(block => <AnalysisSidebarItem key={block.id} analysis={block} minimal={minimal} />)
        )}
        {appStore.env.featureFlags.enabled(FeatureFlag.SPREADSHEET_SUPPORT) && (
          <>
            <Divider />
            <Text variant={TextVariant.Label} color={TextColor.Secondary}>
              Spreadsheets
            </Text>
            {spreadsheets.toReversed().map(spreadsheet => (
              <AnalysisSidebarItem key={spreadsheet.id} analysis={spreadsheet} minimal={minimal} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default observer(AnalysisSidebar);
