import { Handle, NodeProps, Position } from "reactflow";
import classNames from "classnames";
import { observer } from "mobx-react";

import { ExecutionTypeIcon } from "@components/Analysis";
import { Button } from "@components/Button";
import { TitledTooltip } from "@components/Shared";
import { useAppNavigate } from "@router/hooks";
import { IAnalysis } from "@store/Analysis/AnalysisStore";
import { getLanguageFromType, humanReadableDateTime } from "@utilities";

import { CustomNodeType } from "../types";

import "./CodeBlockNode.scss";

/** Type defs. */
type CodeBlockNodeData = {
  type: CustomNodeType;
  id: string;
  analysis: IAnalysis;
  active: boolean;
  activeHandles: Map<string, void>;
};
type CodeBlockNodeProps = NodeProps<CodeBlockNodeData>;

/** Main function. */
function CodeBlockNode(props: CodeBlockNodeProps) {
  const {
    data: { analysis, active, activeHandles },
  } = props;

  const { navigateToCodeBlock } = useAppNavigate();

  return (
    <div className={classNames("code-block-node", { active })}>
      <div className="container">
        <div className="container--header">
          <TitledTooltip
            title={`${getLanguageFromType(analysis.type)} code block`}
            content={`Last updated ${humanReadableDateTime(analysis.updatedAt)}`}
            position={Position.Top}
          >
            <div className="container--title">
              <ExecutionTypeIcon type={analysis.type} size={18} className="code-block-icon" />
              {analysis.label}
            </div>
          </TitledTooltip>
          <div className="spacer" />
          <div className="container--actions">
            <TitledTooltip content="Edit code block">
              <Button small icon="share" minimal onClick={() => navigateToCodeBlock(analysis.id)} e2eIdentifiers="edit-code-block" />
            </TitledTooltip>
          </div>
        </div>
        <div className="content">
          <div className="content--inputs">
            {analysis.inputs.map(input => (
              <div className={classNames("input-entry", { connected: activeHandles?.has(input.id) })} key={input.id}>
                <TitledTooltip content={`Last updated ${humanReadableDateTime(input.updatedAt)}`} position={Position.Right}>
                  <span className="input-entry--label">{input.label}</span>
                </TitledTooltip>

                <Handle className="input-entry--handle" id={input.id} type="target" position={Position.Left}></Handle>
              </div>
            ))}
          </div>
          <div className="content--outputs">
            {analysis.outputs.map(output => (
              <div className={classNames("output-entry", { connected: activeHandles?.has(output.id) })} key={output.id}>
                <TitledTooltip
                  content={`Last updated ${humanReadableDateTime(output.updatedAt)}`}
                  title={output.textValue}
                  position={Position.Left}
                >
                  <span className="output-entry--label">{output.label}</span>
                </TitledTooltip>
                <Handle className="output-entry--handle" id={output.id} type="source" position={Position.Right}></Handle>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

/** Exports. */
export type { CodeBlockNodeData, CodeBlockNodeProps };
export default observer(CodeBlockNode);
