import { Spinner } from "@blueprintjs/core";
import { observer } from "mobx-react";

import styles from "./InfiniteScrollAnchor.module.scss";

interface IInfiniteScrollAnchorProps {
  hasMoreItems: boolean;
}

const InfiniteScrollAnchor = (props: IInfiniteScrollAnchorProps) => {
  const { hasMoreItems } = props;

  return (
    <div className={styles.infiniteScrollAnchor}>
      {hasMoreItems && (
        <div className={styles.infiniteScrollAnchorSpinner}>
          <Spinner size={30} />
        </div>
      )}
    </div>
  );
};

export default observer(InfiniteScrollAnchor);
