import { Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

import { ProjectStatus } from "@components/ProjectManagementSystem/Types/ProjectStatus";
import { IUpdateCustomUnitDto } from "@rollup-api/models/customUnits";
import { IUpdateProjectDto } from "@rollup-api/models/pm/project.dto";
import { updateProject } from "@rollup-api/utils";
import { IUserMobxType, UserStore } from "@store/UserStore";
import { applySanitizedSnapshot } from "@utilities/MobxUtils";

interface ILink {
  url: string;
  label?: string;
}

export const ProjectStore = types
  .model("Project", {
    id: types.identifier,
    label: types.string,
    workspaceId: types.string,
    status: types.enumeration(Object.values(ProjectStatus)),
    designReview: types.boolean,
    description: types.maybe(types.string),
    links: types.array(types.frozen<ILink>()),
    leadId: types.maybe(types.safeReference<IUserMobxType>(UserStore)),
    memberIds: types.array(types.safeReference<IUserMobxType>(UserStore)),
    // TODO to be replaced with ProjectTaskStore
    tasks: types.array(types.string),
    startedAt: types.maybe(types.number),
    finishedAt: types.maybe(types.number),
    targetDate: types.maybe(types.number),
    createdAt: types.optional(types.number, Date.now()),
    createdBy: types.string,
    updatedAt: types.optional(types.number, Date.now()),
    updatedBy: types.string,
  })
  .actions(self => ({
    sendUpdate(updateDto: IUpdateCustomUnitDto) {
      return updateProject(self.id, updateDto);
    },
    patch(update: IUpdateProjectDto) {
      applySanitizedSnapshot(self, update, ["id", "createdAt", "createdBy"]);
    },
    setLabel(label: string, disableNotify?: boolean) {
      self.label = label;
      if (!disableNotify) {
        this.sendUpdate({ label });
      }
    },
  }));

export interface IProject extends Instance<typeof ProjectStore> {}
export interface IProjectSnapshotIn extends SnapshotIn<typeof ProjectStore> {}
interface IProjectSnapshotOut extends SnapshotOut<typeof ProjectStore> {}
export interface IProjectMobxType extends IType<IProjectSnapshotIn, IProjectSnapshotOut, IProject> {}
