import { JSX, MouseEvent } from "react";
import capitalize from "lodash/capitalize";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { Tooltip } from "@components/Tooltip";
import { IE2EIdentifiers } from "@utilities/E2EUtils";

import "./MenuItemDelete.scss";

interface IMenuItemDeleteProps {
  text?: JSX.Element | string;
  e2eIdentifiers?: IE2EIdentifiers;
  disabled?: boolean;
  tooltip?: string;
  onDelete(event: MouseEvent): void;
}

const MenuItemDelete = (props: IMenuItemDeleteProps) => {
  const { text = "Delete", e2eIdentifiers = "delete", disabled, tooltip = "", onDelete } = props;

  return (
    <Tooltip className="menu-item-delete--tooltip" content={tooltip} disabled={!tooltip}>
      <MenuItem
        text={typeof text === "string" ? capitalize(text) : text}
        icon="trash"
        onClick={onDelete}
        e2eIdentifiers={e2eIdentifiers}
        disabled={disabled}
      />
    </Tooltip>
  );
};

export default observer(MenuItemDelete);
