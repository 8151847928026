import { AxiosResponse } from "axios";

import { ICreateProjectDto, IUpdateProjectDto } from "@rollup-api/models/pm/project.dto";
import { Project } from "@rollup-api/models/pm/project.model";
import { trackSegmentEvent } from "src/lib/Segment";

import { HttpClient, ParentClient } from "../client";

export class Projects extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private get endpoint() {
    return `projects`;
  }

  public getAll = (): Promise<AxiosResponse<Project[]>> => {
    return this.instance.get<Project[]>(`${this.endpoint}`);
  };

  public create = (dto: ICreateProjectDto): Promise<AxiosResponse<Project>> => {
    trackSegmentEvent("project:create", dto);
    return this.instance.post<Project>(`${this.endpoint}`, dto);
  };

  public delete = (id: string): Promise<AxiosResponse<Project>> => {
    trackSegmentEvent("project:delete", { id });
    return this.instance.delete<Project>(`${this.endpoint}/${id}`);
  };

  public update = (id: string, dto: IUpdateProjectDto): Promise<AxiosResponse<Project>> => {
    trackSegmentEvent("project:update", { id, ...dto });
    return this.instance.put<Project>(`${this.endpoint}/${id}`, dto);
  };
}
