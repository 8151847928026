import { useState } from "react";
import { Position, Tooltip } from "@blueprintjs/core";
import { DatePicker3 } from "@blueprintjs/datetime2";
import Text, { TextColor, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { Popover } from "@components/Popover";

import "./DateSelector.scss";

export interface DateSelectorProps {
  value?: Date;
  tooltip?: string;
  onChange?: (date?: Date) => void;
}

const DateSelector = ({ value: valueProp, tooltip, onChange }: DateSelectorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<Date | undefined>(valueProp);

  const handlePickDate = (pickedDate?: Date) => {
    setValue(pickedDate);
    setIsOpen(false);
    onChange?.(pickedDate);
  };

  const renderDateSelector = () => {
    return (
      <DatePicker3
        highlightCurrentDay
        value={value}
        onChange={pickedDate => handlePickDate(pickedDate || undefined)}
        showActionsBar
        dayPickerProps={{ className: "date-selector--date-input", showWeekNumber: true }}
      />
    );
  };

  return (
    <Popover isOpen={isOpen} onInteraction={setIsOpen} content={renderDateSelector()} position={Position.BOTTOM_RIGHT}>
      <Tooltip content={tooltip} disabled={!tooltip}>
        <Button className="date-selector--button" minimal icon="calendar" e2eIdentifiers="button-date-selector">
          {value ? (
            <Text variant={TextVariant.Caption} color={TextColor.Primary}>
              {value.toLocaleDateString(undefined, { year: "numeric", month: "short", day: "numeric" })}
            </Text>
          ) : (
            <Text variant={TextVariant.Button2Underline}>Set date</Text>
          )}
        </Button>
      </Tooltip>
    </Popover>
  );
};

export default observer(DateSelector);
