import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";

import { CustomIcon } from "@components/CustomIcon";
import MultiSelectTag from "@components/MultiSelectTag";
import { MultiSelectTagPropsItem } from "@components/MultiSelectTag/MultiSelectTag";
import appStore from "@store/AppStore";
import { ICatalogItemReference } from "@store/CatalogItem/CatalogItemReferenceStore";

import { TCellRendererProps } from "../types";

const ParentRefsCell = (props: TCellRendererProps) => {
  const { data } = props;
  const catalogItem = data?.catalogItem;

  if (!catalogItem) {
    return null;
  }

  const refMapper = (childRef: ICatalogItemReference) => ({
    label: childRef.name || childRef.parentCatalogItem?.name || "",
    id: childRef.id,
    icon: childRef.parentCatalogItem && <CustomIcon icon={childRef.parentCatalogItem.icon} />,
  });

  const selectedItem = data?.catalogItem?.aliveReferences.map(refMapper) ?? [];

  const options = appStore.orgModel.catalogItems.catalogItems.map(i => ({
    label: i.name,
    id: i.id,
    icon: <CustomIcon icon={catalogItem.icon} />,
  }));

  const handleParentSelect = (item: MultiSelectTagPropsItem) => {
    appStore.orgModel.catalogItems.addCatalogItemReference({
      id: uuidv4(),
      referencedCatalogItemId: catalogItem.id,
      parentCatalogItemId: item.id,
    });
  };

  const handleParentRemove = (item: MultiSelectTagPropsItem) => {
    appStore.orgModel.catalogItems.removeCatalogItemReference(item.id);
  };

  return (
    <MultiSelectTag onItemSelect={handleParentSelect} selectedItems={selectedItem} items={options} onItemRemove={handleParentRemove} />
  );
};

export default observer(ParentRefsCell);
